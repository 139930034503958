import axios from "axios";

// const EXTERNAL_API_URL = process.env.NODE_ENV === "production" ? "https://downloads.intern.3points.de" : "http://localhost:8080"
const EXTERNAL_API_URL = process.env.NODE_ENV === "production" ? "https://download.globalextrema.de" : "http://localhost:8080"

const AXIOS = axios.create({
  baseURL: EXTERNAL_API_URL,
  timeout: 150000,
});

class BackendService {

  loadExternalPage(linkId) {
    return AXIOS.get(EXTERNAL_API_URL+'/page/'+linkId)
  }

}


export default new BackendService();
