import { createRouter, createWebHashHistory } from 'vue-router'
import Page from '../views/Page'

const routes = [
  {
    path: '/:link',
    name: 'Page',
    component: Page
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
