const dateTimeFormatter = new Intl.DateTimeFormat("de", {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
})

const timeFormatHelper = function (dateString, fallback = "") {
  if (
    dateString !== undefined &&
    dateString !== null &&
    dateString !== ""
  ) {
    return dateTimeFormatter.format(new Date(dateString));
  }
  return fallback;
};

const fileSizeHelper = function (bytes) {
  if (bytes === 0) {
    return '0 B';
  }
  let k = 1000,
  dm = 3,
  sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export { timeFormatHelper, fileSizeHelper };
