import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

/**
 * PrimeVue Components
 * **/
import PrimeVue from 'primevue/config';

import Button from "primevue/button";
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

/**
 * PrimeVue CSS
 * @type {App<Element>}
 */
import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css'

const app =
  createApp(App)
    .use(router)
    .use(PrimeVue)
    // .use(ConfirmationService)
    // .use(ToastService)

app.component('Button', Button);
app.component('Column', Column);
app.component('DataTable', DataTable);
// app.component('Dialog', Dialog);
// app.component('Toast', Toast);



app.mount('#app');
