<template>
  <div v-if="notFound">
    <h1>Fehler</h1>
    <h3>Seite konnte nicht gefunden werden</h3>
    Vielleicht ist Ihr link abgelaufen oder Sie haben einen fehlerhaften verwendet
  </div>
  <div v-else>
    <h1>{{ page.label }}</h1>
    <div>
      {{ page.description }}
    </div>

    <DataTable :value="page.files" :rowHover="true" class="mt-4">
      <Column field="label" header="Name"></Column>
      <Column field="version" header="Version"></Column>
      <Column field="createDate" header="Erstellt am">
        <template #body="slotProps">
          {{ timeFormat(slotProps.data.createDate) }}
        </template>
      </Column>
      <Column field="size" header="Größe">
        <template #body="slotProps">
          {{ fileSize(slotProps.data.size) }}
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button icon="pi pi-download" v-on:click="download(slotProps.data)"></Button>
        </template>
      </Column>

      <template #empty>
        Momentan stehen keine Downloads zu verfügung.
      </template>

    </DataTable>
  </div>
</template>

<script>
import BackendService from '@/services/backendService';
import {timeFormatHelper, fileSizeHelper} from "@/helper";

export default {
  name: "Page",
  data() {
    return {
      urlLink: "",
      notFound: true,
      page: {
        label: "Label",
        description: "Beschreibung",
        files: []
      }
    }
  },
  methods: {
    timeFormat: timeFormatHelper,
    fileSize: fileSizeHelper,
    routeChanged() {
      console.log(this.$route);
      this.urlLink = this.$route.params.link;
      if (this.urlLink) {
        BackendService.loadExternalPage(this.urlLink).then((r) => {
          this.page = r.data;
          this.notFound = false;
        })
        .catch(() => {
          this.page = {};
          this.notFound = true;
        })
      }
    },
    download(file) {
      window.location = file.link;
    }
  },
  watch: {
    $route() {
      this.routeChanged();
    }
  },

  mounted() {
    this.routeChanged();
  }
}
</script>

<style scoped>

</style>